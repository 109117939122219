import React, { useState } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useForm } from "react-hook-form";

import "./App.css";
import Navbar from "./navbar";
import screenshot from "./img/app-screenshot.png";
import logo854 from "./img/logo854.png";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBbMbFPTjylVyfhNWWMRlXM9opzkKjPk5s",
  authDomain: "kude-south-africa.firebaseapp.com",
  projectId: "kude-south-africa",
  storageBucket: "kude-south-africa.appspot.com",
  messagingSenderId: "287992047986",
  appId: "1:287992047986:web:8b8ae6b265bb802d5055eb",
  measurementId: "G-SMD1FNCJ4T",
};

function App() {
  const [cellphone, setCellphone] = useState("");
  const [otp, setotp] = useState("");
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState("");

  // Initialize Firebase
  initializeApp(firebaseConfig);
  console.log("app initialised");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const auth = getAuth();

  // const onSubmit = (data) => {
  //   console.log("signing in %s", cellphone);
  //   auth
  //     .signInWithPhoneNumber(auth, cellphone, appVerifier)
  //     .then((confirmationResult) => {
  //       // SMS sent. Prompt user to type the code from the message, then sign the
  //       // user in with confirmationResult.confirm(code).
  //       window.confirmationResult = confirmationResult;
  //       // ...
  //     })
  //     .catch((error) => {
  //       // Error; SMS not sent
  //     });
  //   alert("Submitted %s", cellphone);
  // };

  const signin = (e) => {
    e.preventDefault();
    let verify = new RecaptchaVerifier("recaptcha-container", {}, auth);
    let cellphoneZA = "+27" + cellphone.substring(1, 10);
    console.log("signing in with %s", cellphoneZA);
    signInWithPhoneNumber(auth, cellphoneZA, verify)
      .then((result) => {
        setfinal(result);
        alert("OTP sent to " + cellphoneZA);
        setshow(true);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
        window.location.reload();
      });
  };

  const ValidateOtp = () => {
    console.log("otp: %s", otp);
    if (otp === null || final === null) return;
    final
      .confirm(otp)
      .then((result) => {
        // success
        alert(cellphone + "was successfully signed up for Kude!");
      })
      .catch((err) => {
        alert(
          "If you entered the OTP correctly, you have been signed up for Kude!"
        );
      });
  };

  const handleCellphone = (event) => {
    let newNumber = event.target.value;
    console.log("newNumber: %s cellphone: %s", newNumber, cellphone);
    if (newNumber.split("").every((digit) => parseInt(digit) >= 0)) {
      setCellphone(newNumber);
      console.log("new cellphone: %s", newNumber, newNumber.length);
      if (newNumber.length == 10) {
        document.getElementById("submitButton").classList.remove("disabled");
      } else {
        document.getElementById("submitButton").classList.add("disabled");
      }
    } else {
      console.log("problem. new cellphone: %s", cellphone, cellphone.length);
      if (cellphone.length == 10) {
        document.getElementById("submitButton").classList.remove("disabled");
      } else {
        document.getElementById("submitButton").classList.add("disabled");
      }
    }
    console.log("cellphone: %s", cellphone);
  };

  return (
    <>
      <div
        className='container-fluid px-4 px-lg-0'
        style={{ display: "flex", justifyContent: "center" }}
      >
        <img src={screenshot} alt='screenshot' />
      </div>

      <div className='container-fluid px-4 px-lg-0'>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className='mb-4'
        >
          <img height='50px' src={logo854} alt='logo' />
          <h1 className='fw-light'>Kude</h1>
        </div>
        <h2 className='mb-1 text-center fw-normal'>Taxi simplified</h2>
        <p className='mb-5 text-center'>
          Takingh taxis has never been this simple
        </p>
        <form>
          {/* <!-- Email address input--> */}
          <div className='row input-group-newsletter'>
            {!show && (
              <div>
                <div className='col mb-3'>
                  <input
                    className='form-control'
                    id='cellphone'
                    type='tel'
                    placeholder='Enter cellphone number'
                    aria-label='Enter cellphone number'
                    style={{ borderColor: "red" }}
                    value={cellphone}
                    onChange={handleCellphone}
                  />
                </div>
                <div className='col d-grid'>
                  <button
                    className='btn btn-danger disabled'
                    id='submitButton'
                    onClick={signin}
                    type='button'
                  >
                    Sign me up!
                  </button>
                </div>
                <div id='recaptcha-container'></div>
              </div>
            )}
            {show && (
              <div>
                <input
                  type='text'
                  placeholder={"Enter your OTP"}
                  onChange={(e) => {
                    setotp(e.target.value);
                  }}
                ></input>
                <br />
                <br />
                <button onClick={ValidateOtp}>Verify</button>
              </div>
            )}
          </div>
          {errors.cellphone && (
            <div className='invalid-feedback mt-2'>
              Cellphone number is not valid.
            </div>
          )}
        </form>
        <p style={{ marginTop: "10px", fontSize: "small" }}>
          You might receive an SMS message for verification and standard rates
          apply.
        </p>
      </div>
    </>
  );
}

export default App;
