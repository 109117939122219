import { ContainerFluid } from "react";

function TermsAndConditions() {
  return (
    <div
      className='container-fluid px-4 px-lg-0'
      style={{
        marginTop: 40,
        marginBottom: 40,
        backgroundColor: "#e4e8e8",
        padding: 10,
      }}
    >
      <div>PRIVACY NOTICE</div>
      <div>Last updated November 16, 2021</div>
      <div>
        Thank you for choosing to be part of our community at Kude LTD, doing
        business as
      </div>
      <div>
        Kude ("Kude," "we," "us," or "our"). We are committed to protecting your
        personal
      </div>
      <div>
        information and your right to privacy. If you have any questions or
        concerns about
      </div>
      <div>
        this privacy notice or our practices with regard to your personal
        information, please
      </div>
      <div>contact us at systemsprojectapp@gmail.com.</div>
      <div>
        This privacy notice describes how we might use your information if you:
      </div>
      <div>Download and use our mobile application — kude</div>
      <div>
        Engage with us in other related ways ― including any sales, marketing,
        or
      </div>
      <div>events</div>
      <div>In this privacy notice, if we refer to:</div>
      <div>
        "App," we are referring to any application of ours that references or
        links to
      </div>
      <div>this policy, including any listed above</div>
      <div>
        "Services," we are referring to our App, and other related services,
        including
      </div>
      <div>any sales, marketing, or events</div>
      <div>
        The purpose of this privacy notice is to explain to you in the clearest
        way possible
      </div>
      <div>
        what information we collect, how we use it, and what rights you have in
        relation to it.
      </div>
      <div>
        If there are any terms in this privacy notice that you do not agree
        with, please
      </div>
      <div>discontinue use of our Services immediately.</div>
      <div>
        Please read this privacy notice carefully, as it will help you
        understand what we
      </div>
      <div>do with the information that we collect.</div>
      <div>TABLE OF CONTENTS</div>
      <div>1. WHAT INFORMATION DO WE COLLECT?</div>
      <div>2. HOW DO WE USE YOUR INFORMATION?</div>
      <div>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</div>
      <div>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</div>
      <div>5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</div>
      <div>6. HOW LONG DO WE KEEP YOUR INFORMATION?</div>
      <div>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</div>
      <div>8. WHAT ARE YOUR PRIVACY RIGHTS?</div>
      <div>9. CONTROLS FOR DO-NOT-TRACK FEATURES</div>
      <div>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</div>
      <div>11. DO WE MAKE UPDATES TO THIS NOTICE?</div>
      <div>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</div>
      <div>13. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT</div>
      <div>FROM YOU?</div>
      <div>1. WHAT INFORMATION DO WE COLLECT?</div>
      <div>Personal information you disclose to us</div>
      <div>
        In Short: We collect personal information that you provide to us.
      </div>
      <div>
        We collect personal information that you voluntarily provide to us when
        you register
      </div>
      <div>
        on the App, express an interest in obtaining information about us or our
        products and
      </div>
      <div>
        Services, when you participate in activities on the App or otherwise
        when you contact
      </div>
      <div>us.</div>
      <div>
        The personal information that we collect depends on the context of your
        interactions
      </div>
      <div>
        with us and the App, the choices you make and the products and features
        you use.
      </div>
      <div>The personal information we collect may include the following:</div>
      <div>
        Personal Information Provided by You. We collect names; phone numbers;
        email
      </div>
      <div>addresses; passwords; and other similar information.</div>
      <div>
        All personal information that you provide to us must be true, complete
        and accurate,
      </div>
      <div>
        and you must notify us of any changes to such personal information.
      </div>
      <div>Information automatically collected</div>
      <div>
        In Short: Some information — such as your Internet Protocol (IP) address
        and/or
      </div>
      <div>
        browser and device characteristics — is collected automatically when you
        visit our
      </div>
      <div>App.</div>
      <div>
        We automatically collect certain information when you visit, use or
        navigate the App.
      </div>
      <div>
        This information does not reveal your specific identity (like your name
        or contact
      </div>
      <div>
        information) but may include device and usage information, such as your
        IP address,
      </div>
      <div>
        browser and device characteristics, operating system, language
        preferences,
      </div>
      <div>
        referring URLs, device name, country, location, information about how
        and when you
      </div>
      <div>
        use our App and other technical information. This information is
        primarily needed to
      </div>
      <div>
        maintain the security and operation of our App, and for our internal
        analytics and
      </div>
      <div>reporting purposes.</div>
      <div>
        Like many businesses, we also collect information through cookies and
        similar
      </div>
      <div>technologies.</div>
      <div>The information we collect includes:</div>
      <div>
        Log and Usage Data. Log and usage data is service-related, diagnostic,
        usage
      </div>
      <div>
        and performance information our servers automatically collect when you
      </div>
      <div>
        access or use our App and which we record in log files. Depending on how
        you
      </div>
      <div>
        interact with us, this log data may include your IP address, device
        information,
      </div>
      <div>
        browser type and settings and information about your activity in the App
        (such
      </div>
      <div>
        as the date/time stamps associated with your usage, pages and files
        viewed,
      </div>
      <div>
        searches and other actions you take such as which features you use),
        device
      </div>
      <div>
        event information (such as system activity, error reports (sometimes
        called
      </div>
      <div>'crash dumps') and hardware settings).</div>
      <div>
        Device Data. We collect device data such as information about your
        computer,
      </div>
      <div>
        phone, tablet or other device you use to access the App. Depending on
        the
      </div>
      <div>
        device used, this device data may include information such as your IP
        address
      </div>
      <div>
        (or proxy server), device and application identification numbers,
        location,
      </div>
      <div>
        browser type, hardware model Internet service provider and/or mobile
        carrier,
      </div>
      <div>operating system and system configuration information.</div>
      <div>
        Location Data. We collect location data such as information about your
      </div>
      <div>
        device's location, which can be either precise or imprecise. How much
      </div>
      <div>
        information we collect depends on the type and settings of the device
        you use
      </div>
      <div>
        to access the App. For example, we may use GPS and other technologies to
      </div>
      <div>
        collect geolocation data that tells us your current location (based on
        your IP
      </div>
      <div>
        address). You can opt out of allowing us to collect this information
        either by
      </div>
      <div>
        refusing access to the information or by disabling your Location setting
        on your
      </div>
      <div>
        device. Note however, if you choose to opt out, you may not be able to
        use
      </div>
      <div>certain aspects of the Services.</div>
      <div>Information collected through our App</div>
      <div>
        In Short: We collect information regarding your geolocation, push
        notifications,
      </div>
      <div>when you use our App.</div>
      <div>If you use our App, we also collect the following information:</div>
      <div>
        Geolocation Information. We may request access or permission to and
        track
      </div>
      <div>
        location-based information from your mobile device, either continuously
        or
      </div>
      <div>
        while you are using our App, to provide certain location-based services.
        If you
      </div>
      <div>
        wish to change our access or permissions, you may do so in your device's
      </div>
      <div>settings.</div>
      <div>
        Push Notifications. We may request to send you push notifications
        regarding
      </div>
      <div>
        your account or certain features of the App. If you wish to opt-out from
      </div>
      <div>
        receiving these types of communications, you may turn them off in your
      </div>
      <div>device's settings.</div>
      <div>
        This information is primarily needed to maintain the security and
        operation of our
      </div>
      <div>
        App, for troubleshooting and for our internal analytics and reporting
        purposes.
      </div>
      <div></div>
      <div>2. HOW DO WE USE YOUR INFORMATION?</div>
      <div>
        In Short: We process your information for purposes based on legitimate
        business
      </div>
      <div>
        interests, the fulfillment of our contract with you, compliance with our
        legal
      </div>
      <div>obligations, and/or your consent.</div>
      <div>
        We use personal information collected via our App for a variety of
        business purposes
      </div>
      <div>
        described below. We process your personal information for these purposes
        in
      </div>
      <div>
        reliance on our legitimate business interests, in order to enter into or
        perform a
      </div>
      <div>
        contract with you, with your consent, and/or for compliance with our
        legal obligations.
      </div>
      <div>
        We indicate the specific processing grounds we rely on next to each
        purpose listed
      </div>
      <div>below.</div>
      <div>We use the information we collect or receive:</div>
      <div>
        To facilitate account creation and logon process. If you choose to link
        your
      </div>
      <div>
        account with us to a third-party account (such as your Google or
        Facebook
      </div>
      <div>
        account), we use the information you allowed us to collect from those
        third
      </div>
      <div>
        parties to facilitate account creation and logon process for the
        performance of
      </div>
      <div>the contract.</div>
      <div>
        To post testimonials. We post testimonials on our App that may contain
      </div>
      <div>
        personal information. Prior to posting a testimonial, we will obtain
        your consent
      </div>
      <div>
        to use your name and the content of the testimonial. If you wish to
        update, or
      </div>
      <div>
        delete your testimonial, please contact us at
        systemsprojectapp@gmail.com
      </div>
      <div>
        and be sure to include your name, testimonial location, and contact
      </div>
      <div>information.</div>
      <div>
        Request feedback. We may use your information to request feedback and to
      </div>
      <div>contact you about your use of our App.</div>
      <div>
        To enable user-to-user communications. We may use your information in
      </div>
      <div>
        order to enable user-to-user communications with each user's consent.
      </div>
      <div>
        To manage user accounts. We may use your information for the purposes of
      </div>
      <div>managing our account and keeping it in working order.</div>
      <div>
        To send administrative information to you. We may use your personal
      </div>
      <div>
        information to send you product, service and new feature information
        and/or
      </div>
      <div>
        information about changes to our terms, conditions, and policies.
      </div>
      <div>
        To protect our Services. We may use your information as part of our
        efforts
      </div>
      <div>
        to keep our App safe and secure (for example, for fraud monitoring and
      </div>
      <div>prevention).</div>
      <div>
        To enforce our terms, conditions and policies for business purposes, to
      </div>
      <div>
        comply with legal and regulatory requirements or in connection with our
      </div>
      <div>contract.</div>
      <div>
        To respond to legal requests and prevent harm. If we receive a subpoena
      </div>
      <div>
        or other legal request, we may need to inspect the data we hold to
        determine
      </div>
      <div>how to respond.</div>
      <div>
        Fulfill and manage your orders. We may use your information to fulfill
        and
      </div>
      <div>
        manage your orders, payments, returns, and exchanges made through the
      </div>
      <div>App.</div>
      <div>
        Administer prize draws and competitions. We may use your information to
      </div>
      <div>
        administer prize draws and competitions when you elect to participate in
        our
      </div>
      <div>competitions.</div>
      <div>
        To deliver and facilitate delivery of services to the user. We may use
        your
      </div>
      <div>information to provide you with the requested service.</div>
      <div>
        To respond to user inquiries/offer support to users. We may use your
      </div>
      <div>
        information to respond to your inquiries and solve any potential issues
        you
      </div>
      <div>might have with the use of our Services.</div>
      <div>3. WILL YOUR INFORMATION BE SHARED WITH</div>
      <div>ANYONE?</div>
      <div>
        In Short: We only share information with your consent, to comply with
        laws, to
      </div>
      <div>
        provide you with services, to protect your rights, or to fulfill
        business obligations.
      </div>
      <div>
        We may process or share your data that we hold based on the following
        legal basis:
      </div>
      <div>
        Consent: We may process your data if you have given us specific consent
        to
      </div>
      <div>use your personal information for a specific purpose.</div>
      <div>
        Legitimate Interests: We may process your data when it is reasonably
      </div>
      <div>necessary to achieve our legitimate business interests.</div>
      <div>
        Performance of a Contract: Where we have entered into a contract with
        you,
      </div>
      <div>
        we may process your personal information to fulfill the terms of our
        contract.
      </div>
      <div>
        Legal Obligations: We may disclose your information where we are legally
      </div>
      <div>
        required to do so in order to comply with applicable law, governmental
      </div>
      <div>
        requests, a judicial proceeding, court order, or legal process, such as
        in
      </div>
      <div>
        response to a court order or a subpoena (including in response to public
      </div>
      <div>
        authorities to meet national security or law enforcement requirements).
      </div>
      <div>
        Vital Interests: We may disclose your information where we believe it is
      </div>
      <div>
        necessary to investigate, prevent, or take action regarding potential
        violations
      </div>
      <div>
        of our policies, suspected fraud, situations involving potential threats
        to the
      </div>
      <div>
        safety of any person and illegal activities, or as evidence in
        litigation in which
      </div>
      <div>we are involved.</div>
      <div>
        More specifically, we may need to process your data or share your
        personal
      </div>
      <div>information in the following situations:</div>
      <div>
        Business Transfers. We may share or transfer your information in
        connection
      </div>
      <div>
        with, or during negotiations of, any merger, sale of company assets,
        financing,
      </div>
      <div>
        or acquisition of all or a portion of our business to another company.
      </div>
      <div>
        Google Maps Platform APIs. We may share your information with certain
      </div>
      <div>
        Google Maps Platform APIs (e.g., Google Maps API, Place API). To find
        out
      </div>
      <div>
        more about Google’s Privacy Policy, please refer to this link. We obtain
        and
      </div>
      <div>
        store on your device ('cache') your location. You may revoke your
        consent
      </div>
      <div>
        anytime by contacting us at the contact details provided at the end of
        this
      </div>
      <div>document.</div>
      <div></div>
      <div>4. DO WE USE COOKIES AND OTHER TRACKING</div>
      <div>TECHNOLOGIES?</div>
      <div></div>
      <div>
        In Short: We may use cookies and other tracking technologies to collect
        and store
      </div>
      <div>your information.</div>
      <div>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels)
      </div>
      <div>
        to access or store information. Specific information about how we use
        such
      </div>
      <div>
        technologies and how you can refuse certain cookies is set out in our
        Cookie Notice.
      </div>
      <div></div>
      <div>5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</div>
      <div></div>
      <div>
        In Short: We are not responsible for the safety of any information that
        you share
      </div>
      <div>
        with third-party providers who advertise, but are not affiliated with,
        our Website.
      </div>
      <div>
        The App may contain advertisements from third parties that are not
        affiliated with us
      </div>
      <div>
        and which may link to other websites, online services or mobile
        applications. We
      </div>
      <div>
        cannot guarantee the safety and privacy of data you provide to any third
        parties. Any
      </div>
      <div>
        data collected by third parties is not covered by this privacy notice.
        We are not
      </div>
      <div>
        responsible for the content or privacy and security practices and
        policies of any third
      </div>
      <div>
        parties, including other websites, services or applications that may be
        linked to or
      </div>
      <div>
        from the App. You should review the policies of such third parties and
        contact them
      </div>
      <div>directly to respond to your questions.</div>
      <div>6. HOW LONG DO WE KEEP YOUR INFORMATION?</div>
      <div>
        In Short: We keep your information for as long as necessary to fulfill
        the purposes
      </div>
      <div>
        outlined in this privacy notice unless otherwise required by law.
      </div>
      <div>
        We will only keep your personal information for as long as it is
        necessary for the
      </div>
      <div>
        purposes set out in this privacy notice, unless a longer retention
        period is required or
      </div>
      <div>
        permitted by law (such as tax, accounting or other legal requirements).
        No purpose in
      </div>
      <div>
        this notice will require us keeping your personal information for longer
        than the period
      </div>
      <div>of time in which users have an account with us.</div>
      <div>
        When we have no ongoing legitimate business need to process your
        personal
      </div>
      <div>
        information, we will either delete or anonymize such information, or, if
        this is not
      </div>
      <div>
        possible (for example, because your personal information has been stored
        in backup
      </div>
      <div>
        archives), then we will securely store your personal information and
        isolate it from
      </div>
      <div>any further processing until deletion is possible.</div>
      <div>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</div>
      <div>
        In Short: We aim to protect your personal information through a system
        of
      </div>
      <div>organizational and technical security measures.</div>
      <div>
        We have implemented appropriate technical and organizational security
        measures
      </div>
      <div>
        designed to protect the security of any personal information we process.
        However,
      </div>
      <div>
        despite our safeguards and efforts to secure your information, no
        electronic
      </div>
      <div>
        transmission over the Internet or information storage technology can be
        guaranteed
      </div>
      <div>
        to be 100% secure, so we cannot promise or guarantee that hackers,
        cybercriminals,
      </div>
      <div>
        or other unauthorized third parties will not be able to defeat our
        security, and
      </div>
      <div>
        improperly collect, access, steal, or modify your information. Although
        we will do our
      </div>
      <div>
        best to protect your personal information, transmission of personal
        information to and
      </div>
      <div>
        from our App is at your own risk. You should only access the App within
        a secure
      </div>
      <div>environment.</div>
      <div>8. WHAT ARE YOUR PRIVACY RIGHTS?</div>
      <div>
        In Short: You may review, change, or terminate your account at any time.
      </div>
      <div>
        If you are a resident in the EEA or UK and you believe we are unlawfully
        processing
      </div>
      <div>
        your personal information, you also have the right to complain to your
        local data
      </div>
      <div>
        protection supervisory authority. You can find their contact details
        here:
      </div>
      <div>
        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
      </div>
      <div>
        If you are a resident in Switzerland, the contact details for the data
        protection
      </div>
      <div>
        authorities are available here:
        https://www.edoeb.admin.ch/edoeb/en/home.html.
      </div>
      <div>
        If you have questions or comments about your privacy rights, you may
        email us at
      </div>
      <div>systemsprojectapp@gmail.com.</div>
      <div>Account Information</div>
      <div>
        If you would at any time like to review or change the information in
        your account or
      </div>
      <div>terminate your account, you can:</div>
      <div>Contact us using the contact information provided.</div>
      <div>
        Upon your request to terminate your account, we will deactivate or
        delete your
      </div>
      <div>
        account and information from our active databases. However, we may
        retain some
      </div>
      <div>
        information in our files to prevent fraud, troubleshoot problems, assist
        with any
      </div>
      <div>
        investigations, enforce our Terms of Use and/or comply with applicable
        legal
      </div>
      <div>requirements.</div>
      <div>
        Opting out of email marketing: You can unsubscribe from our marketing
        email list
      </div>
      <div>
        at any time by clicking on the unsubscribe link in the emails that we
        send or by
      </div>
      <div>
        contacting us using the details provided below. You will then be removed
        from the
      </div>
      <div>
        marketing email list — however, we may still communicate with you, for
        example to
      </div>
      <div>
        send you service-related emails that are necessary for the
        administration and use of
      </div>
      <div>
        your account, to respond to service requests, or for other non-marketing
        purposes.
      </div>
      <div>To otherwise opt-out, you may:</div>
      <div>Contact us using the contact information provided.</div>
      <div>9. CONTROLS FOR DO-NOT-TRACK FEATURES</div>
      <div>
        Most web browsers and some mobile operating systems and mobile
        applications
      </div>
      <div>
        include a Do-Not-Track ("DNT") feature or setting you can activate to
        signal your
      </div>
      <div>
        privacy preference not to have data about your online browsing
        activities monitored
      </div>
      <div>
        and collected. At this stage no uniform technology standard for
        recognizing and
      </div>
      <div>
        implementing DNT signals has been finalized. As such, we do not
        currently respond
      </div>
      <div>
        to DNT browser signals or any other mechanism that automatically
        communicates
      </div>
      <div>
        your choice not to be tracked online. If a standard for online tracking
        is adopted that
      </div>
      <div>
        we must follow in the future, we will inform you about that practice in
        a revised
      </div>
      <div>version of this privacy notice.</div>
      <div></div>
      <div>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC</div>
      <div>PRIVACY RIGHTS?</div>
      <div>
        In Short: Yes, if you are a resident of California, you are granted
        specific rights
      </div>
      <div>regarding access to your personal information.</div>
      <div>
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law,
      </div>
      <div>
        permits our users who are California residents to request and obtain
        from us, once a
      </div>
      <div>
        year and free of charge, information about categories of personal
        information (if any)
      </div>
      <div>
        we disclosed to third parties for direct marketing purposes and the
        names and
      </div>
      <div>
        addresses of all third parties with which we shared personal information
        in the
      </div>
      <div>
        immediately preceding calendar year. If you are a California resident
        and would like
      </div>
      <div>
        to make such a request, please submit your request in writing to us
        using the contact
      </div>
      <div>information provided below.</div>
      <div>
        If you are under 18 years of age, reside in California, and have a
        registered account
      </div>
      <div>
        with the App, you have the right to request removal of unwanted data
        that you
      </div>
      <div>
        publicly post on the App. To request removal of such data, please
        contact us using
      </div>
      <div>
        the contact information provided below, and include the email address
        associated
      </div>
      <div>
        with your account and a statement that you reside in California. We will
        make sure
      </div>
      <div>
        the data is not publicly displayed on the App, but please be aware that
        the data may
      </div>
      <div>
        not be completely or comprehensively removed from all our systems (e.g.
        backups,
      </div>
      <div>etc.).</div>
      <div>11. DO WE MAKE UPDATES TO THIS NOTICE?</div>
      <div>
        In Short: Yes, we will update this notice as necessary to stay compliant
        with
      </div>
      <div>relevant laws.</div>
      <div>
        We may update this privacy notice from time to time. The updated version
        will be
      </div>
      <div>
        indicated by an updated "Revised" date and the updated version will be
        effective as
      </div>
      <div>
        soon as it is accessible. If we make material changes to this privacy
        notice, we may
      </div>
      <div>
        notify you either by prominently posting a notice of such changes or by
        directly
      </div>
      <div>
        sending you a notification. We encourage you to review this privacy
        notice frequently
      </div>
      <div>to be informed of how we are protecting your information.</div>
      <div>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</div>
      <div>
        If you have questions or comments about this notice, you may email us at
      </div>
      <div>systemsprojectapp@gmail.com or by post to:</div>
      <div>Kude LTD</div>
      <div>__________</div>
      <div>Century City</div>
      <div>Cape Town, Western Cape 7441</div>
      <div>South Africa</div>
      <div>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE</div>
      <div>DATA WE COLLECT FROM YOU?</div>
      <div>
        Based on the applicable laws of your country, you may have the right to
        request
      </div>
      <div>
        access to the personal information we collect from you, change that
        information, or
      </div>
      <div>
        delete it in some circumstances. To request to review, update, or delete
        your
      </div>
      <div>
        personal information, please submit a request form by clicking her
      </div>
    </div>
  );
}

export default TermsAndConditions;
