import { useState } from "react";

import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <Nav
      activeKey='/'
      onSelect={(selectedKey) => alert(`selected ${selectedKey}`)}
      style={{ position: "fixed", width: "100%", top: 10, left: 10 }}
    >
      <Nav.Item>
        {/* <Nav.Link href='/'>Home</Nav.Link> */}
        <Link to='/'>Home</Link>
      </Nav.Item>
      <Nav.Item>
        {/* <Nav.Link href='/terms-and-conditions'>T's & C's</Nav.Link> */}
        <Link to='/terms-and-conditions' style={{ marginLeft: 10 }}>
          T's & C's
        </Link>
      </Nav.Item>
    </Nav>
  );
}

export default Navbar;
