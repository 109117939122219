import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import "./css/styles.css";
import "./App.css";
import App from "./App";
import Navbar from "./navbar";
import TermsAndConditions from "./terms-and-conditions";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className='masthead-content text-black'>
        <Routes>
          <Route path='/*' element={<Navbar />} />
        </Routes>
        <Routes>
          <Route path='/' element={<App />}></Route>
          <Route
            path='/terms-and-conditions'
            element={<TermsAndConditions />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("form")
);

// ReactDOM.render(
//   <React.StrictMode>
//     <Navbar />
//   </React.StrictMode>,
//   document.getElementById("navbar")
// );
